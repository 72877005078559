<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="operators"
            sort-by="id"
            class="elevation-1"
            locale="sv"
            :items-per-page="25"
            dense
        >
            <template v-slot:[`item.status`]="{ item }">
                <v-tooltip left v-if="parseInt(item.status) === 0">
                    <template v-slot:activator="{ on }">
                        <v-icon
                            v-on="on"
                            @click="changeStatus(item, 1)">
                            mdi-account-off
                        </v-icon>
                    </template>
                    <span>Konto nieaktywne, kliknij aby aktywować.</span>
                </v-tooltip>
                <v-tooltip left v-else-if="item.name === userData.login">
                    <template v-slot:activator="{ on }">
                        <v-icon
                            v-on="on"
                            color="primary">
                            mdi-account
                        </v-icon>
                    </template>
                    <span>Konto na którym jesteś aktualnie zalogowany\a.</span>
                </v-tooltip>
                <v-tooltip left v-else>
                    <template v-slot:activator="{ on }">
                        <v-icon
                            v-on="on"
                            color="success"
                            @click="changeStatus(item, 0)">
                            mdi-account
                        </v-icon>
                    </template>
                    <span>Konto aktywne, kliknij aby dezaktywować.</span>
                </v-tooltip>
            </template>
            <template v-slot:top>
                <v-toolbar flat dense>
                    <v-toolbar-title>Operatorzy</v-toolbar-title>
                    <v-spacer/>
                    <v-dialog v-model="dialog" max-width="700px">
                        <template v-slot:activator="{ on }">
                            <v-btn
                                tile
                                color="primary"
                                dark
                                v-on="on"
                                small
                                @click="$refs.form ? $refs.form.reset() : null"
                            >
                                <v-icon class="mr-1">mdi-account-plus</v-icon> Dodaj operatora
                            </v-btn>
                        </template>
                        <v-card>
                            <v-form
                                autocomplete="off"
                                ref="form"
                                v-model="validSaveDataStop"
                                lazy-validation
                                @submit.prevent="save"
                            >
                                <v-card-title>
                                    <span class="headline">Dodaj operatora</span>
                                </v-card-title>

                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col cols="12" sm="6" md="8" class="py-0">
                                                <v-text-field
                                                    v-model="editedItem.name"
                                                    label="Nazwa (unikalna)"
                                                    hint="Np. jkowalski"
                                                    persistent-hint
                                                    :rules="nameRules"
                                                    :error-messages="errors.name"
                                                    outlined
                                                    dense
                                                />
                                            </v-col>
                                            <v-col cols="12" sm="6" md="4" class="py-0">
                                                <v-switch
                                                    color="blue"
                                                    v-model="editedItem.status"
                                                    label="Aktywny"
                                                    class="mt-1"
                                                    false-value="0"
                                                    true-value="1"
                                                    dense
                                                />
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>

                                <v-card-actions>
                                    <v-spacer/>
                                    <v-btn color="error" tile small outlined @click="close">Anuluj</v-btn>
                                    <v-btn
                                        tile
                                        small
                                        color="primary"
                                        type="submit">Zapisz</v-btn>
                                </v-card-actions>
                            </v-form>
                        </v-card>
                    </v-dialog>
                    <v-btn
                        to="/"
                        class="ml-3"
                        dark
                        small>
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
            </template>
        </v-data-table>
    </div>
</template>

<script>
export default {
    name: "Operators",
    props: {
        userData: {
            type: Object,
            required: true
        },
    },
    data: () => ({
        dialog: false,
        headers: [
            { text: 'ID', value: 'id' },
            {
                text: 'Nazwa',
                align: 'left',
                value: 'name',
            },
            { text: 'Status', value: 'status' },
        ],
        operators: [],
        editedIndex: -1,
        editedItem: {
            name: '',
            status: 0,
        },
        defaultItem: {
            name: '',
            status: 0,
        },
        errors: {
            name: ''
        },
        nameRules: [v => !!v || 'Pole obowiązkowe!'],
        validSaveDataStop: true
    }),
    created() {
        this.getOperators();
    },
    methods: {
        getOperators () {
            this.$http.get('a/u/l') // Pobieranie listy operatorów
                .then((response) => {
                    this.operators = response.data;
                })
                .catch(() => {
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Wystąpił błąd.',
                        text: 'Podczas pobierania listy operatorów.'
                    });
                });
        },
        changeStatus (item, newStatus) {
            let editIndex = this.operators.indexOf(item);

            if (editIndex > -1) {
                item.status = newStatus;

                this.$http.post('a/u/s/' + item.id + '/' + newStatus) //  Zmiana statusu
                    .then((response) => {
                        if (response.status === 0) {
                            this.$notify({
                                group: 'foo',
                                type: 'error',
                                title: 'Wystąpił błąd.',
                                text: 'Podczas zmiany statusu operatora.'
                            });
                        } else {
                            this.$notify({
                                group: 'foo',
                                type: 'success',
                                title: 'Zaktualizowano status',
                                text: 'Zaktualizowano status operatora ' + item.name
                            });
                            Object.assign(this.operators[editIndex], item);
                        }
                    })
                    .catch(() => {
                        this.$notify({
                            group: 'foo',
                            type: 'error',
                            title: 'Wystąpił błąd.',
                            text: 'Podczas zmiany statusu operatora.'
                        });
                    });
            }
        },
        close () {
            this.dialog = false;
            this.editedItem = Object.assign({}, this.defaultItem);
            this.editedIndex = -1;
            this.errors = [];
        },
        save () {
            if(this.$refs.form.validate()) {
                this.editedIndex = this.operators.indexOf(this.editedItem);
                this.$http.post('a/u/c', this.editedItem) // Zapis użytkownika
                    .then(() => {
                        this.errors = [];
                        this.$notify({
                            group: 'foo',
                            type: 'success',
                            title: 'Dodano operatora',
                            text: 'Dodano nowego operatora ' + this.editedItem.name
                        });
                        this.getOperators();
                        this.close();
                    })
                    .catch((error) => {
                        this.errors = error.response.data.errors;
                        this.$notify({
                            group: 'foo',
                            type: 'error',
                            title: 'Wystąpił błąd.',
                            text: 'Podczas dodawania nowego operatora.'
                        });
                    });
            } else {
                this.$notify({
                    group: 'foo',
                    type: 'warn',
                    text: 'Popraw dane w formularzu.'
                });
            }
        },
    }
}
</script>
