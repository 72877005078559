var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.operators,"sort-by":"id","locale":"sv","items-per-page":25,"dense":""},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [(parseInt(item.status) === 0)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({on:{"click":function($event){return _vm.changeStatus(item, 1)}}},on),[_vm._v(" mdi-account-off ")])]}}],null,true)},[_c('span',[_vm._v("Konto nieaktywne, kliknij aby aktywować.")])]):(item.name === _vm.userData.login)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"primary"}},on),[_vm._v(" mdi-account ")])]}}],null,true)},[_c('span',[_vm._v("Konto na którym jesteś aktualnie zalogowany\\a.")])]):_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"success"},on:{"click":function($event){return _vm.changeStatus(item, 0)}}},on),[_vm._v(" mdi-account ")])]}}],null,true)},[_c('span',[_vm._v("Konto aktywne, kliknij aby dezaktywować.")])])]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","dense":""}},[_c('v-toolbar-title',[_vm._v("Operatorzy")]),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"700px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"tile":"","color":"primary","dark":"","small":""},on:{"click":function($event){_vm.$refs.form ? _vm.$refs.form.reset() : null}}},on),[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-account-plus")]),_vm._v(" Dodaj operatora ")],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-form',{ref:"form",attrs:{"autocomplete":"off","lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}},model:{value:(_vm.validSaveDataStop),callback:function ($$v) {_vm.validSaveDataStop=$$v},expression:"validSaveDataStop"}},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Dodaj operatora")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6","md":"8"}},[_c('v-text-field',{attrs:{"label":"Nazwa (unikalna)","hint":"Np. jkowalski","persistent-hint":"","rules":_vm.nameRules,"error-messages":_vm.errors.name,"outlined":"","dense":""},model:{value:(_vm.editedItem.name),callback:function ($$v) {_vm.$set(_vm.editedItem, "name", $$v)},expression:"editedItem.name"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-switch',{staticClass:"mt-1",attrs:{"color":"blue","label":"Aktywny","false-value":"0","true-value":"1","dense":""},model:{value:(_vm.editedItem.status),callback:function ($$v) {_vm.$set(_vm.editedItem, "status", $$v)},expression:"editedItem.status"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","tile":"","small":"","outlined":""},on:{"click":_vm.close}},[_vm._v("Anuluj")]),_c('v-btn',{attrs:{"tile":"","small":"","color":"primary","type":"submit"}},[_vm._v("Zapisz")])],1)],1)],1)],1),_c('v-btn',{staticClass:"ml-3",attrs:{"to":"/","dark":"","small":""}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }